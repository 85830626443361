<template>
    <router-view></router-view>
    <h1>Master Kurs</h1>
    <hr/>
    <bs-error-message :errors="errorMessages"/>
  
    <div class="row justify-content-between">
      <div class="col-md-6">
        <div class="d-grid gap-3 d-md-block">
          <router-link to="/master-kurs/create" class="btn btn-primary btn-sm me-md-1">
            <i class="bi bi-file-earmark"></i> Create
          </router-link>
  
        </div>
      </div>
      <div class="col-md-6">
        <div class="pagination-right">
          <bs-pagination :pageNumber="pageNumber" :perPage="perPage" :totalPage="totalPage" :totalRow="totalRow"
                         @toPage="toPage"/>
        </div>
      </div>
    </div>
  
    <div class="">
      <table class="table table-striped">
        <thead>
            <tr>
                <th rowspan="2">
                    Action
                </th>
                <th>
                    <bs-ordering property="start_date" @reorder="reorder">Tanggal Awal Berlaku</bs-ordering>
                </th>
                <th>
                    <bs-ordering property="end_date" @reorder="reorder">Tanggal Akhir Berlaku</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="currency_form" @reorder="reorder">Currency Asal</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="currency_to" @reorder="reorder">Currency Tujuan</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="convertion_rate" @reorder="reorder">Nilai Kurs</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="supplier" @reorder="reorder">Supplier</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="location" @reorder="reorder">Location</bs-ordering>
                </th>
                <th>
                  <bs-ordering property="source" @reorder="reorder">Sumber</bs-ordering>
                </th>
            </tr>
            <tr>
                <th>
                    <div>
                        <bs-date-picker placeholder="start_date_from" v-model="filter.startDateFrom" @change="search()" />
                        <bs-date-picker placeholder="start_date_to" v-model="filter.startDateTo" @change="search()" />
                    </div>
                </th>
                <th>
                    <div>
                        <bs-date-picker placeholder="end_date_from" v-model="filter.endDateFrom" @change="search()" />
                        <bs-date-picker placeholder="end_date_to" v-model="filter.endDateTo" @change="search()" />
                    </div>
                </th>
                <th>
                    <div>
                        <bs-textbox placeholder="currency_from" v-model="filter.CurrencyFromCodeContains" @keyup="search()" />
                    </div>
                </th>
                <th>
                    <div>
                        <bs-textbox placeholder="currency_to" v-model="filter.CurrencyToCodeContains" @keyup="search()" />
                    </div>
                </th>
                <th>
                    <div>
                        <bs-textbox placeholder="convertion_rate" v-model="filter.ConversionRateEquals" @keyup="search()" />
                    </div>
                </th>
                <th>
                    <div>
                        <bs-textbox placeholder="supplier" v-model="filter.SupplierNameContains" @keyup="search()" />
                    </div>
                </th>
                <th>
                    <div>
                        <bs-textbox placeholder="location" v-model="filter.LocationNameContains" @keyup="search()" />
                    </div>
                </th>
                <th>
                    <div>
                        <bs-textbox placeholder="source" v-model="filter.SourceContains" @keyup="search()" />
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
        <template v-if="data != null && data.length > 0">
          <tr v-for="item in data" :key="item.id">
            <td>
              <div class="btn-group">
                <button class="btn btn-sm btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="bi bi-menu-down"></i> Action
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <button @click="detailItem(item)" class="dropdown-item"><i class="bi bi-eye"></i> View Details
                    </button>
                  </li>
                  <li>
                    <button @click="editItem(item)" class="dropdown-item"><i class="bi bi-pencil-square"></i> Edit
                    </button>
                  </li>
                  <!--TODO: hapus komen jika ingin mengaktifkan fitur hapus kurs -->
<!--                  <li>-->
<!--                    <button @click="deleteItem(item)" class="dropdown-item"><i class="bi bi-trash"></i> Delete</button>-->
<!--                  </li>-->
                </ul>
              </div>
            </td>
            <td>
              <view-date v-model="item.startDate"/>
            </td>
            <td>
              <view-date v-model="item.endDate"/>
            </td>
            <td>
              {{ item.currencyFromCode ?? '-' }}
            </td>
            <td>
              {{ item.currencyToCode ?? '-'}}
            </td>
            <td>
              {{ item.conversionRate ?? '-' }}
            </td>
            <td>
              {{ item.supplierName ?? '-'}}
            </td>
            <td>
              {{ item.locationName ?? '-'}}
            </td>
            <td>
              {{ item.source ?? '-'}}
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import MasterKursService from "@/services/master-kurs-service.js";
  import ViewDate from "@/components/ViewDate.vue";
  import BsDatePicker from "@/plugins/components/BsDatePicker.vue";

  export default {
    components: {BsDatePicker, ViewDate},
    data: function () {
      return {
        filter: {
          startDateFrom: null,
          startDateTo: null,
          endDateFrom: null,
          endDateTo: null,
          CurrencyFromCodeContains: '',
          CurrencyToCodeContains: '',
          ConversionRateEquals: '',
          SupplierNameContains: '',
          LocationNameContains: '',
          SourceContains: '',
          page: 1,
          perPage: 10,
          orderByProperty: 'id',
          orderType: 0
        },
        errorMessages: null,
        pageNumber: 1,
        perPage: 10,
        totalPage: 100,
        totalRow: 0,
        data: Array()
      }
    },
    mounted() {
      this.loadData();
    },
    methods: {
      async toPage(pageNumber) {
        this.filter.page = pageNumber;
        this.loadData();
      },
      async reorder(property, orderType) {
        this.filter.orderByProperty = property;
        this.filter.orderType = orderType;
        this.loadData();
      },
      async search() {
        this.filter.page = 1;
        this.loadData();
      },
      async loadData() {
        const result = await MasterKursService.getList(this.filter);
        if (result.status) {
          this.errorMessages = null;
        } else {
          this.errorMessages = result.errorMessages;
        }
        const data = result.data;
        this.pageNumber = data.pageNumber;
        this.perPage = data.perPage;
        this.totalPage = data.totalPage;
        this.totalRow = data.totalRow;
        this.data = data.data;
      },
      detailItem(item) {
        this.$router.push(`/master-kurs/detail/${item.id}`);
      },
      editItem(item) {
        this.$router.push(`/master-kurs/edit/${item.id}`);
      },
      async deleteItem(item) {
        if (confirm(`Are you sure want to delete this kurs`)) {
          const result = await MasterKursService.delete(item.id);
          if (result.status) {
            this.loadData();
          }
        }
      }
    },
    watch: {
      $route: function () {
        if (this.$route.path == "/master-kurs") {
          this.loadData();
        }
      }
    },
  }
  </script>
  
  <style>
  
  </style>